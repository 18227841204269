import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  CircularProgress, Box, Button, Grid, Card, CardHeader, Divider, CardContent,
  styled, Table, TableBody, TableRow, TableCell, Rating, Alert, Input, TextField
  , Dialog, List, ListItem, DialogTitle, ListItemButton, ListItemText, IconButton,
  ListItemAvatar, Avatar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCart } from '../../../contexts/CartContext';
import coupon_dicount_image from '../../../images/icons8-discount-48.png';
import blue_coupon_dicount_image from '../../../images/icons8-discount-64_blue.png';

const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const permalink = (row) => {
  var subject = row.subject_name;
  var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
  if (row.type === 'practice') {
    var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
  } else {
    var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
  }
  return url;
}

function PublicCouponDialog(props) {
  const { publicCoupons, onClose, selectedValue, open } = props;
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'xs'} fullWidth={true}>
      <DialogTitle>All offers for you</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
        })}
      >
        <CloseIcon />
      </IconButton>
      <List sx={{ pt: 0 }}>
        {publicCoupons.map((coupon, key) => (
          <ListItem disablePadding key={coupon.code}>
            <ListItemAvatar>
              <img src={blue_coupon_dicount_image} alt="Coupon Dicount" height="50px;" />
            </ListItemAvatar>
            <ListItemButton onClick={() => handleListItemClick(coupon.code)} style={{ 'display': 'list-item' }}>
              Instant Discount upto {coupon.discount_upto}{(coupon.discount_type === 'percentage') ? '%' : ''}
              <br />
              Get flat {coupon.discount_value}{(coupon.discount_type === 'percentage') ? '%' : ''} Discount on your total Payment
              <br />
              <Button color="success" variant="outlined" size={'small'}>
                <img src={coupon_dicount_image} alt="Coupon Dicount" height="20px;" />
                {coupon.code}
              </Button>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Dialog>
  );
}

function ViewCart() {
  const [searchPapers, setSearchPapers] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState('');
  const { cart, totalCartItems, removeItemFromCart, cartCheckOut, couponCode, setCouponCode, applyCouponCode, removeCouponCode, couponExpired } = useCart();
  const [publicCoupons, setPublicCoupons] = useState([]);
  const [openPublicCoupon, setOpenPublicCoupon] = useState(false);
  const [selectedPublicCouponValue, setSelectedPublicCouponValue] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (!coupon) {
      removeCouponCode();
    }
    if (selectedPublicCouponValue) {
      applyCouponCode();
    }
  }, [coupon]);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    try {
      var api_url = '/api/frontend/get-public-coupons';
      axios.post(api_url)
        .then(response => {
          if (response.data.response_code === 200) {
            setPublicCoupons(response.data.data);

          } else if (response.data.response_code === 401) {

          } else {

          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (e) {
    }
  }

  const handleCouponCode = event => {
    setCoupon(event.target.value);
    setCouponCode(event.target.value)
  };

  const handleRemoveCouponCode = event => {
    setSelectedPublicCouponValue(false);
    setCouponCode('')
    setCoupon('');
  };

  const handlePublicCouponDicount = event => {
    setOpenPublicCoupon(true);
  };

  const handlePublicCouponClose = (value) => {
    setCouponCode(value)
    setCoupon(value);
    setSelectedPublicCouponValue(value);
    setOpenPublicCoupon(false);
  };

  return (
    <>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Cart</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <h1 className="btn-shine">Cart</h1>
            {totalCartItems ? (
              <Grid container item rowSpacing={2} columnSpacing={4}>
                <Grid item lg={8} sm={12} xs={12}>
                  <Card>
                    <CardHeader
                      title={`${(totalCartItems) ?? '0'} items in Cart`}
                      titleTypographyProps={{
                        variant: "h2",
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                      sx={{ background: "#f4f4f4" }} />
                    <Divider />
                    <CardContent style={{ padding: "0px" }}>
                      <StyledTable>
                        <TableBody>
                          {cart && cart.cart_items && (
                            cart.cart_items.map((row, index) => (
                              <TableRow key={`tbl-cart-${index}`}>
                                <TableCell align="left" sx={{ width: "120px" }}>
                                  <Link to={permalink(row)}>
                                    <img src={(row.thumb_nail_url ? row.thumb_nail_url : ((row.thumb_nail_image) ?? row.thumb_nail_image))} alt={row.topic_title} />
                                  </Link>
                                </TableCell>
                                <TableCell align="left" title={row.topic_title}>
                                  <h6 className="product_title">
                                    <Link to={permalink(row)} title={row.topic_title}>
                                      {row.topic_title.replace(/(.{50})..+/, "$1…")}
                                    </Link>
                                  </h6>
                                  <span>{row.author_name}</span>
                                  <div className="rating_wrap">
                                    <span className="product_title">
                                      {(() => {
                                        switch (row.organization_type_id) {
                                          case (1 || '1'):
                                            return `${row.class_name}`;
                                          case (2 || '2'):
                                            return `${row.university_name}`;
                                          case (3 || '3'):
                                            return `${row.entrance_exam_name}`;
                                          case (4 || '4'):
                                            return `${row.competitive_exam_name}`;
                                          default:
                                            return null;
                                        }
                                      })()}
                                    </span>
                                    {/* <span className="rating_num">({row.avg_rating})</span>
                                    <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                                    <Button disableElevation sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}>
                                      ({row.total_review ?? '0'} Rating)
                                    </Button>
                                     */}
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <Button title='Remove form Cart' onClick={() => removeItemFromCart(row.id)} sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}>Remove</Button>
                                  <br />
                                  {/* <Button title='Add to Wishlist' onClick={() => removeItemFromCart(row.id)} sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}> Save For Later</Button> */}

                                </TableCell>
                                <TableCell align="center" sx={{ width: "60px" }}>
                                  {(row.gst_price) ? (
                                    <>
                                      <span className="price" >
                                        {process.env.REACT_APP_CURRENCY} {row.gst_total_selling_price}
                                      </span>
                                      <br />
                                      <span className="price cart-price-dsc" >
                                        {process.env.REACT_APP_CURRENCY} {row.gst_total_price}
                                      </span>
                                      <span>{row.discount_percentage ? `(${row.discount_percentage}%)` : ''}</span>
                                    </>
                                  ) : 'Free'}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody >
                      </StyledTable>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                  <Card style={{ background: "#F4F4F4" }}>
                    <CardHeader
                      title="Cart Total :"
                      titleTypographyProps={{
                        variant: "h2",
                        fontSize: '18px',
                        fontWeight: '600',
                      }}
                    />
                    <Divider />
                    <CardContent>
                      {cart && (
                        <>
                          <span className="price cart-price">{process.env.REACT_APP_CURRENCY} {cart.cart_total_selling_price}</span>
                          <span className="price cart-price-dsc" >
                            {process.env.REACT_APP_CURRENCY} {cart.cart_total_price}
                          </span>
                          <br />
                          <br />
                          <Alert style={{ 'display': 'block' }}
                            icon={false}
                          >
                            <img src={coupon_dicount_image} alt="Coupon Dicount" height="40px;" />
                            Many offers available for you.
                            <Button style={{ 'float': 'right' }} variant="text" onClick={handlePublicCouponDicount}>
                              <strong>View All</strong>
                            </Button>
                          </Alert>
                          <PublicCouponDialog
                            publicCoupons={publicCoupons}
                            selectedValue={selectedPublicCouponValue}
                            open={openPublicCoupon}
                            onClose={handlePublicCouponClose}
                          />
                          <br />
                          {(cart.cart_total_coupon_discount_price != 0) ? <>
                            <Alert
                              severity="success"
                              onClose={() => handleRemoveCouponCode()}
                            >
                              <strong>{couponCode}</strong> Coupon applied successfully.
                            </Alert>
                          </> : <>
                            <form>

                              <TextField
                                value={coupon}
                                onChange={handleCouponCode}
                                id="standard-basic"
                                label="Coupon code"
                                variant="outlined"
                                sx={{ width: "80%" }}
                                size='small'
                              />
                              <Button variant="contained"
                                type="button"
                                className="whtebtn pull-right"
                                onClick={() => applyCouponCode()}
                              >
                                Apply
                              </Button>

                              {(cart.cart_total_coupon_discount_price == 0 && couponExpired == 'expired') ? <>
                                <br />
                                <br />
                                <Alert
                                  severity="error"
                                >
                                  <strong>Coupon code hase been expired.</strong>
                                </Alert>
                              </> : <>
                              </>}
                            </form>
                          </>}
                          <br />
                          <Link to={'#'} onClick={() => cartCheckOut()} className="btn btn-fill-out rounded-0 checkout display-block">
                            Checkout
                          </Link>
                          {/* <Link to={'/checkout'} className="btn btn-fill-out rounded-0 checkout display-block">
                          Checkout
                        </Link> */}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <>
                <Alert severity="error">Cart is empty — check it out!</Alert>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            )}
          </div >
        </div >
      </div >
    </>
  );
}
export default ViewCart;

